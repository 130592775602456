import {
   IonContent,
   IonIcon,
   IonInfiniteScroll,
   IonInfiniteScrollContent,
   IonItem,
   IonLabel,
   IonList,
   IonPage,
   IonText,
   useIonActionSheet, useIonToast,
} from "@ionic/react";
import React, {useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {Header, LoadingPage} from "../../components";
import OrdersAdmin from "../../repositories/admin/orders";
import api from "../../utils/api";
import {Order, OrderStates} from "../../models";
import {calendar, musicalNote, person, timer} from "ionicons/icons";
import moment from "moment";

export const OrdersPage: React.FC<RouteComponentProps> = ({match}) => {
   const repo = new OrdersAdmin(api)
   let timeout: any;
   const [state, setState] = React.useState<{ orders: Order[], lastOrderId?: number }>({
      orders: [],
   });
   const [loading, setLoading] = React.useState<boolean>(true);
   const [present] = useIonActionSheet()

   useEffect(() => {
      loadOrders()
      const timer = setInterval(() => {
         loadOrders()
      }, 2000)
      return () => clearInterval(timer)
   }, [])

   const [presentToast] = useIonToast()
   const loadOrders = async (page = 1) => {
      const {meta, data} = await repo.getAll({page});
      if (data.length) {
         if (!!state.lastOrderId) {
            const newOrders = data.filter(o => o.id > state.lastOrderId!)
            console.log(newOrders)
            if (newOrders.length > 0) {
               const lastOrder = newOrders[newOrders.length - 1]
               setState({
                  orders: [...state.orders, ...newOrders],
                  lastOrderId: +lastOrder.id,
               })
               await presentToast({
                  header: 'Nuevo pedido',
                  message: `Mesa ${lastOrder.mesa.name} ha pedido ${lastOrder.song.name}`,
                  duration: 2000,
               })
            }
         } else {
            const lastOrder = data[data.length - 1]
            setState({orders: data, lastOrderId: +lastOrder.id})
         }
      }
      setLoading(false)
   }
   const colorState = (state: string) => {
      const colors: { [param: string]: string } = {
         'aceptado': 'success',
         'pendiente': 'warning',
         'rechazado': 'danger',
         'reproducido': '#ff0000',
      }
      return colors[state] || ''
   }

   const handleOrderClick = async (order: Order) => {
      await present({
         header: 'Cambiar Estado',
         mode: 'ios',
         buttons: [
            ...Object.entries(OrderStates).map(([key, value]) => ({
               text: key,
               handler: () => handleStateChange(order, value),
            })),
            {text: 'Cancelar', role: 'cancel', cssClass: 'ion-color-danger'}
         ],
      })
   }


   const handleStateChange = async (order: Order, status: string) => {
      const reason = ''
      await repo.update(order.id, {reason, state: status})
      const {orders} = state
      setState({...state, orders: orders.map(o => o.id === order.id ? {...o, state: status} : o)})
      if ([OrderStates.Rechazado, OrderStates.Reproducido].includes(status as OrderStates)) {
         setTimeout(() => {
            setState({...state, orders: orders.filter(o => o.id !== order.id)})
         }, 1000)
      }
   }

   return (
      <IonPage>
         <Header title="Pedidos" root/>
         {loading && <LoadingPage/>}
         {!loading &&
            <IonContent fullscreen>
               <IonList lines="full">
                  {state.orders.length === 0 && <IonItem color={'success'}>No hay pedidos</IonItem>}
                  {state.orders.map((order, i) => (

                     <IonItem key={i} onClick={() => handleOrderClick(order)} button>
                        <div slot="start">{order.mesa.name}</div>
                        <IonLabel>
                           <h2><IonIcon icon={musicalNote}/> {order.song.name} </h2>
                           <p>
                              <IonIcon icon={person}/> {order.song.artist.name} / <IonIcon
                              icon={calendar}/> {order.song.year} / <IonIcon
                              icon={timer}/> {order.song.duration} / Genero: {order.song.genre}
                           </p>
                        </IonLabel>
                        <IonLabel slot="end" class="ion-text-uppercase ion-text-right ">
                           <p>
                              <IonText color={colorState(order.state)}>{order.state}</IonText>
                              <br/>
                              <small>{moment(order.created_at).format('DD/MM/YYYY')}</small>
                           </p>
                        </IonLabel>
                     </IonItem>
                  ))}
               </IonList>
               <IonInfiniteScroll onIonInfinite={ev => {
                  console.log('onIonInfinite', ev)
               }}>
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
               </IonInfiniteScroll>
            </IonContent>}
      </IonPage>
   )
}
