import {create} from 'zustand';
import {Song} from "../models";


interface IAppStore {
   listeners: number,
   favourites: Song[],
   incListener: () => void,
   decListener: () => void,
   favouriteAdd: (song: Song) => void,
   favouriteRemove: (song: Song) => void,
}

export const useAppStore = create<IAppStore>(set => ({
   listeners: 0,
   favourites: [],

   incListener: () => set(state => ({...state, listeners: state.listeners + 1})),

   decListener: () => set(state => ({...state, listeners: state.listeners - 1})),

   favouriteAdd: (song: Song) => set(state => {
      const newFavourites = [...state.favourites, song]
      return {...state, favourites: newFavourites}
   }),

   favouriteRemove: (song: Song) => set(state => {
      return {...state, favourites: state.favourites.filter(fav => fav.id !== song.id)}
   }),
}))
