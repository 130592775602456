import {IonIcon} from "@ionic/react";
import {star, starOutline} from "ionicons/icons";

interface RankingProps {
   rank: number,
   slot?: string
}

export const Ranking = ({rank, slot}: RankingProps) => {
   const stars = Array.from({length: rank});
   const outlines = Array.from({length: 5 - rank});

   return (
      <>
         {stars.map((_, i) => <IonIcon icon={star} key={i} color="warning"/>)}
         {outlines.map((_, i) => <IonIcon icon={starOutline} key={i} color="warning"/>)}
      </>
   );
}
