import {
   IonContent,
   IonItem,
   IonMenu,
   IonRouterOutlet,
   IonSplitPane,
   useIonRouter,
} from "@ionic/react";
import {Redirect, Route} from "react-router-dom";
import {OrdersPage} from "./OrdersPage";
import {RouteComponentProps, Switch} from "react-router";
import React, {useEffect} from "react";
import {useLoginStore} from "../../store";
import {LoadingPage, NotFound} from "../../components";
import {MenuAdmin} from "./components/MenuAdmin";
import {ArtistsPage} from "./ArtistsPage";
import {SongsPage} from "./SongsPage";
import {UsersPage} from "./UsersPage";
import {ArtistEditPage} from "./ArtistEditPage";
import {SongPage} from "./SongPage";
import {SongEditPage} from "./SongEditPage";
import {ArtistPage} from "./ArtistPage";
import {UserNewPage} from "./UserNewPage";
import {SalonsPage} from "./SalonsPage";
import {MesasPage} from "./MesasPage";

export const AdminLayout: React.FC<RouteComponentProps> = ({match}) => {
   console.log("AdminPage", match);

   const [loading, setLoading] = React.useState(true);
   const {session, sessionLoaded} = useLoginStore((state) => state);
   const navigation = useIonRouter();

   useEffect(() => {
      if (sessionLoaded && !session)
         navigation.push("/login", "root", "replace");
      else setLoading(false);
   }, []);

   if (loading) return <LoadingPage/>;

   return (
      <IonSplitPane contentId="main">
         <MenuAdmin/>
         <IonRouterOutlet id="main">
            <Route exact path="/admin/orders" component={OrdersPage}/>
            <Route exact path="/admin/artists" component={ArtistsPage}/>
            <Route exact path="/admin/artists/:artistId" component={ArtistPage}/>
            <Route exact path="/admin/artists/new" component={ArtistEditPage}/>
            <Route exact path="/admin/artists/:artistId/edit" component={ArtistEditPage}/>
            <Route exact path="/admin/artists/:artistId/songs/new" component={SongEditPage}/>
            <Route exact path="/admin/songs" component={SongsPage}/>
            <Route exact path="/admin/songs/:songId" component={SongPage}/>
            <Route exact path="/admin/songs/:songId/edit" component={SongEditPage}/>
            <Route exact path="/admin/salons" component={SalonsPage}/>
            <Route exact path="/admin/salons/:salonId/mesas" component={MesasPage}/>
            <Route exact path="/admin/users" component={UsersPage}/>
            <Route exact path="/admin/users/new" component={UserNewPage}/>
            <Route exact path="/admin">
               <Redirect to={"/admin/orders"}/>
            </Route>
            {/*<Redirect path={'/admin'} to={'/admin/dashboard'}/>*/}
         </IonRouterOutlet>
      </IonSplitPane>
   );
};
