import {
   IonButton,
   IonCard,
   IonCardContent,
   IonCardHeader,
   IonCardTitle,
   IonCol,
   IonContent,
   IonInput,
   IonItem,
   IonLabel,
   IonPage,
   IonRouterLink,
   IonRow,
   useIonRouter,
   useIonToast,
} from "@ionic/react";
import React, {FormEvent, useEffect, useState} from "react";
import {LoadingPage} from "../components";
import {RouteComponentProps} from "react-router";
import {useLoginStore} from "../store";
import api from "../utils/api";
import Auth from "../repositories/auth";

// const style = require('./LoginPage.css')

export const LoginPage: React.FC<RouteComponentProps> = ({match}) => {
   console.log('LoginPage', match)

   const repoAuth = new Auth(api);

   const navigation = useIonRouter();
   const [presentToast] = useIonToast()
   const [loading, setLoading] = useState(true);
   const [form, setForm] = useState({email: '', password: ''})
   const {session, setSession} = useLoginStore(state => state);

   const checkLogin = async () => {
      let isOk: boolean = false
      if (session && session.token) {
         isOk = await (session.isClient ? repoAuth.statusClient() : repoAuth.statusAdmin())
         if (isOk)
            return navigation.push(session.isClient
               ? '/app/orders' : '/admin/orders', 'root', 'replace')
      }
      setLoading(false)
   }

   const handleLogin = async (e: FormEvent) => {
      e.preventDefault()
      if (!form.email || !form.password)
         return await presentToast({
            message: 'Ingrese usuario y contraseña', duration: 2000, color: 'danger',
         })
      try {
         console.log('login')
         const {data} = await repoAuth.login(form)
         setSession(data)
         navigation.push('/admin/orders', 'root', 'replace')
      } catch (e: unknown) {
         await presentToast({
            message: 'Ocurrio un error al iniciar sesión',
            duration: 2000,
            color: 'danger',
         })
      }
   }

   useEffect(() => {
      checkLogin();
   }, [])

   return (
      <IonPage>
         <IonContent fullscreen>
            {loading
               ? <LoadingPage label={'Iniciando'}/>
               : <IonRow className="ion-align-items-center" style={{height: '100%'}}>
                  <IonCol sizeMd="6" offsetMd="3" sizeLg="4" offsetLg="4">
                     <IonCard>
                        <IonCardHeader>
                           <IonCardTitle>Iniciar Sesión</IonCardTitle>
                        </IonCardHeader>
                        <form onSubmit={handleLogin}>
                           <IonCardContent>
                              <IonItem>
                                 <IonLabel position="floating">Usuario:</IonLabel>
                                 <IonInput value={form.email} name={'email'} autocomplete={'email'}
                                           onIonChange={(e) => setForm({
                                              ...form,
                                              email: e.target.value?.toString() || '',
                                           })}/>
                              </IonItem>
                              <IonItem>
                                 <IonLabel position="floating">Contraseña:</IonLabel>
                                 <IonInput type="password" value={form.password} name={'password'}
                                           autocomplete={'current-password'}
                                           onIonChange={({target: {value}}) => {
                                              setForm({
                                                 ...form,
                                                 password: value?.toString() || '',
                                              })
                                           }}/>
                              </IonItem>
                              <br/>
                              <IonButton type="submit" expand="block"
                                         color="dark">Ingresar</IonButton>
                           </IonCardContent>
                        </form>
                     </IonCard>
                     <IonRow className="ion-justify-content-center">
                        <IonRouterLink href="https://fretelweb.com" color="dark" target="_blank">
                           <small>dSign by FretelWEB.com</small>
                        </IonRouterLink>
                     </IonRow>

                  </IonCol>
               </IonRow>
            }
         </IonContent>
      </IonPage>
   );
}
