import React, {Suspense, useEffect} from "react";
import {IonRouterOutlet, IonSplitPane, useIonRouter} from "@ionic/react";
import {Route} from "react-router-dom";
import {MenuClient} from "./components/MenuClient";
import {RouteComponentProps} from "react-router";
import {useLoginStore} from "../../store";
import {LoadingPage} from "../../components";

const TabsPage = React.lazy(() => import('./TabsPage'));
export const ClientLayout: React.FC<RouteComponentProps> = ({match}) => {
   // console.log('ClientLayout', match)

   const [loading, setLoading] = React.useState(true);
   const {session, sessionLoaded} = useLoginStore(state => state);
   const navigation = useIonRouter();

   useEffect(() => {
      if (sessionLoaded && !session) {
         navigation.push('/', 'root', 'replace');
      } else {
         setLoading(false)
      }
   }, []);

   if (loading) return (<LoadingPage/>)

   return (
      <IonSplitPane contentId="main">
         <MenuClient/>
         <IonRouterOutlet id="main">
            <Suspense fallback={<LoadingPage/>}>
               <Route path='/app' component={TabsPage}/>
            </Suspense>
         </IonRouterOutlet>
      </IonSplitPane>
   );
}
