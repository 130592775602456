import React, { useEffect } from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import { IonNote, IonText } from "@ionic/react";

interface ErrorHookFormProps {
   errors: FieldErrors;
   field: string;
   slot?: "helper" | "error";
}

export const ErrorHookForm: React.FC<ErrorHookFormProps> = ({
   field,
   errors,
   slot = "error",
}) => {
   console.log(errors[field]?.message);
   const [error, setError] = React.useState<any>();
   useEffect(() => {
      const error = errors[field];
      setError(error?.message);
   }, [errors, field]);

   if (!errors || !errors[field]) return <></>;

   return (
      <IonNote slot={slot}>
         <IonText color={slot === "error" ? "danger" : "white"}>
            <small> {String(errors[field]?.message)}</small>
         </IonText>
      </IonNote>
   );
};
