import {
   IonAvatar,
   IonButton,
   IonCard,
   IonCardContent,
   IonCardHeader,
   IonCardTitle,
   IonCol,
   IonContent,
   IonIcon,
   IonImg,
   IonItem,
   IonLabel,
   IonList,
   IonPage,
   IonRow,
   IonToolbar, useIonAlert, useIonRouter,
   useIonViewDidEnter,
} from "@ionic/react";
import {useParams} from "react-router";
import {Header, LoadingPage, Ranking} from "../../components";
import {useState} from "react";
import ArtistsAdmin from "../../repositories/admin/artists";
import api from "../../utils/api";
import {Artist, Song} from "../../models";
import {ListDescription} from "../../components/ListDescription";
import {chevronForward} from "ionicons/icons";
import {remoteUrl} from "../../utils/url";
import {useArtistsStore, useSongsStore} from "../../store";

export const ArtistPage = () => {
   const repo = new ArtistsAdmin(api)

   const {artistId} = useParams<{ artistId: string }>();
   const [artist, setArtist] = useState<Artist>()
   const [loading, setLoading] = useState<boolean>(true)

   const [present] = useIonAlert()
   const navigation = useIonRouter()
   const {deleteArtist} = useArtistsStore(state => state)
   const {songs, addSongs, clearSongs} = useSongsStore(state => state)

   useIonViewDidEnter(() => {
      console.log('ionViewDidEnter', artistId)
      clearSongs()
      loadArtist();
   })
   const loadArtist = async () => {
      const {data} = await repo.get(artistId);
      const {meta, data: songs} = await repo.getSongs(artistId, {page: 1})
      setArtist(data)
      addSongs(songs)
      setLoading(false)
   }
   const handleRemoveClick = async () => {
      if (!artist) return;
      present({
         header: 'Eliminar artista',
         message: `¿Estás seguro de eliminar el artista ${artist.name}?`,
         buttons: [{text: 'Cancelar', role: 'cancel'},
            {
               text: 'Eliminar',
               handler: async () => {
                  await repo.delete(artist.id)
                  deleteArtist(artist.id)
                  navigation.goBack()
               },
            }],
      })
   }

   return (
      <IonPage>
         <Header title={artist?.name || ''}/>
         {(loading || !artist)
            ? <LoadingPage label="Cargando artista"/>
            : <IonContent fullscreen>
               <IonCard>
                  <IonCardContent>
                     <IonRow>
                        <IonCol size="12" sizeMd="4" className="ion-text-center">
                           {<IonImg src={remoteUrl(artist.image)} style={{width: '100%'}}/>}
                        </IonCol>
                        <IonCol size="12" sizeMd={artist.image ? '8' : '12'}>
                           <h1>{artist.name}</h1>
                           <p>{artist.description}</p>

                           <IonList>
                              <IonItem>
                                 <IonLabel>Ranking</IonLabel>
                                 <div slot={'end'}>
                                    <Ranking rank={artist.ranking}/>
                                 </div>
                              </IonItem>
                           </IonList>

                           <IonToolbar>
                              <IonRow className="ion-justify-content-between">
                                 <IonCol>
                                    <IonButton routerLink={`/admin/artists/${artistId}/edit`}
                                               fill="solid" color="primary" expand="block">
                                       Editar
                                    </IonButton>
                                 </IonCol>
                                 <IonCol>
                                    <IonButton fill="solid" color="danger" expand="block"
                                               onClick={handleRemoveClick}>
                                       Eliminar
                                    </IonButton>
                                 </IonCol>
                              </IonRow>
                           </IonToolbar>

                        </IonCol>
                     </IonRow>
                  </IonCardContent>
               </IonCard>

               <IonCard>
                  <IonCardHeader>
                     <IonItem>
                        <IonCardTitle>Canciones</IonCardTitle>
                        <IonButton slot="end" routerLink={`/admin/artists/${artistId}/songs/new`}>
                           Agregar
                        </IonButton>
                     </IonItem>

                  </IonCardHeader>
                  <IonCardContent>
                     <IonList>
                        {songs.map((song, index) => (
                           <IonItem routerLink={`/admin/songs/${song.id}`} key={index}>
                              <IonAvatar slot="start">
                                 <IonImg src={remoteUrl(song.image)}/>
                              </IonAvatar>
                              <IonLabel>{song.name}</IonLabel>
                              <IonIcon icon={chevronForward} slot="end"/>
                           </IonItem>
                        ))}
                     </IonList>
                  </IonCardContent>
               </IonCard>
            </IonContent>
         }
      </IonPage>
   );
}
