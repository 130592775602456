import './ArtistsPage.css';
import {
   InfiniteScrollCustomEvent,
   IonAvatar,
   IonButton,
   IonButtons,
   IonContent, IonFab, IonFabButton,
   IonIcon,
   IonImg,
   IonInfiniteScroll,
   IonInfiniteScrollContent,
   IonItem,
   IonLabel,
   IonList,
   IonPage,
} from "@ionic/react";
import {Header, Ranking} from "../../components";
import api from "../../utils/api";
import {useEffect, useState} from "react";
import {PaginationMeta} from "../../models";
import ArtistsAdmin from "../../repositories/admin/artists";
import {add, chevronForward, person} from "ionicons/icons";
import {remoteUrl} from "../../utils/url";
import {useArtistsStore} from "../../store";

export const ArtistsPage = () => {
   const repo = new ArtistsAdmin(api);

   const [meta, setMeta] = useState<PaginationMeta>();
   const {artists, addArtists, clearArtists} = useArtistsStore(state => state);
   const loadArtists = async (page = 1) => {
      const {meta, data} = await repo.getAll({page});
      setMeta(meta);
      if (page === 1) clearArtists()
      addArtists(data);
   }

   useEffect(() => {
      loadArtists();
   }, []);

   const loadMore = async (e: InfiniteScrollCustomEvent) => {
      if (!meta) return;
      if (meta.current_page < meta.last_page) {
         await loadArtists(meta.current_page + 1);
      }
      await e.target.complete();
   }
   const handleImport = async (e: any) => {
      console.log('Import file', e.target.files[0])
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('csv', file);
      await repo.import(formData);
      await loadArtists();

   };

   return (
      <IonPage>
         <Header title={'Artistas'} root icon={person}>
            <IonButtons slot="end">
               <IonButton>
                  <label htmlFor="import">CSV</label>
                  <input className={'hideInput'} id={'import'} type={'file'} accept=".csv" onChange={handleImport}/>
               </IonButton>
               <IonButton routerLink="/admin/artists/new" fill={'solid'} color={'primary'}>
                  <IonIcon icon={add} slot={'start'}/> Nuevo
               </IonButton>
            </IonButtons>
         </Header>
         <IonContent fullscreen>
            <IonList>
               {artists.map((artist, index) => (
                  <IonItem key={index} routerLink={`/admin/artists/${artist.id}`}>
                     <IonAvatar slot="start">
                        <IonImg src={remoteUrl(artist.image)}/>
                     </IonAvatar>
                     <IonLabel>
                        <h2> {artist.name} </h2>
                        <Ranking rank={artist.ranking}/>
                     </IonLabel>
                     <IonIcon icon={chevronForward} slot="end"/>
                  </IonItem>
               ))}
            </IonList>
            <IonInfiniteScroll onIonInfinite={loadMore}>
               <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
         </IonContent>
      </IonPage>
   );
}
