import {
   IonButton,
   IonButtons,
   IonCard,
   IonCardContent,
   IonCol,
   IonContent,
   IonImg,
   IonInput,
   IonItem,
   IonLabel,
   IonPage,
   IonRange,
   IonRow,
   IonTextarea,
   useIonRouter,
} from "@ionic/react";
import {Header, LoadingPage} from "../../components";
import {useForm} from "react-hook-form";
import {useSongsStore} from "../../store";
import {useEffect, useState} from "react";
import {Song} from "../../models";
import SongsAdmin from "../../repositories/admin/songs";
import api from "../../utils/api";
import {useParams} from "react-router";
import {remoteUrl} from "../../utils/url";

export const SongEditPage = () => {
   const repo = new SongsAdmin(api);
   const navigation = useIonRouter();

   const {register, handleSubmit, getValues, reset, formState: {errors}} = useForm<Partial<Song>>();
   const {songs, update, addSong} = useSongsStore((state) => state);
   const {songId, artistId} = useParams<{ songId: string, artistId?: string }>();
   const [image, setImage] = useState<string>();
   const [file, setFile] = useState<File>();
   const [loading, setLoading] = useState(true)
   const [isNew, setIsNew] = useState(false)

   useEffect(() => {
      if (songId && songId !== 'new') {
         setIsNew(false)
         loadSong()
      }
      else {
         setIsNew(true)
         setLoading(false)
      }
   }, [songId]);

   const loadSong = async () => {
      const {data} = await repo.get(songId)
      setImage(remoteUrl(data?.image))
      reset(data)
      setLoading(false)
   }
   const onSubmit = async (data: any) => {

      const formData = new FormData()
      formData.append("name", data.name)
      formData.append("description", data.description)
      formData.append('genre', data.genre)
      formData.append('ranking', data.ranking)
      formData.append('year', data.year)
      formData.append('duration', data.duration)

      if (artistId) formData.append('artistId', artistId)
      if (file) formData.append("file", file)

      const {data: song} = isNew
         ? await repo.create(formData)
         : await repo.update(songId, formData)
      isNew ? addSong(song) : update(song)
      navigation.goBack()
   };

   const handleImageChange = async (e: any) => {
      const file = e.target.files[0];
      setFile(file)
      const reader = new FileReader();
      reader.onloadend = () => {
         setImage(reader.result as string)
      }
      reader.readAsDataURL(file);
   }

   return (
      <IonPage>
         <Header title={isNew ? 'Nueva canción' : "Editar Canción"}>
            <IonButtons slot="end">
               <IonButton onClick={handleSubmit(onSubmit)} fill={'solid'} color={'primary'}>
                  Guardar
               </IonButton>
            </IonButtons>
         </Header>
         {loading
            ? <LoadingPage label={'Cargando'}/>
            : <IonContent fullscreen>
               <IonCard>
                  <IonCardContent>

                     <IonRow>
                        <IonCol size={'12'} sizeMd={'4'}>
                           <IonImg src={image} style={{width: "100%"}}/>
                        </IonCol>
                        <IonCol sizeMd={'8'}>
                           <IonItem>
                              <IonLabel position={"stacked"}>Nombre</IonLabel>
                              <IonInput
                                 {...register("name", {
                                    required: "El nombre es requerido",
                                 })}
                              />
                              {/*<ErrorHookForm errors={errors} field="name"/>*/}
                           </IonItem>
                           <IonItem>
                              <IonLabel position={"stacked"}>Descripción</IonLabel>
                              <IonTextarea {...register("description")} rows={4}/>
                           </IonItem>
                           <IonItem>
                              <IonLabel position={"fixed"}>Genero</IonLabel>
                              <IonInput {...register("genre")} slot={'end'}/>
                           </IonItem>
                           <IonItem>
                              <IonLabel position={"fixed"}>Año</IonLabel>
                              <IonInput type="number" {...register("year")} slot={'end'}/>
                           </IonItem>
                           <IonItem>
                              <IonLabel>Duración</IonLabel>
                              <IonInput {...register("duration")} slot={'end'}/>
                           </IonItem>
                           <IonItem>
                              <IonLabel position={'fixed'}>Ranking {}</IonLabel>
                              <IonRange {...register('ranking')} ticks snaps pin min={1} max={5}
                                        slot={'end'}/>
                           </IonItem>
                           <IonItem>
                              <IonLabel position={"fixed"}>Imágen</IonLabel>
                              <input type="file" onChange={handleImageChange} accept={'image/*'}
                                     slot={'end'}/>
                           </IonItem>
                        </IonCol>
                     </IonRow>

                  </IonCardContent>
               </IonCard>
            </IonContent>
         }
      </IonPage>
   );
};
