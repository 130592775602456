import {
   IonBackButton,
   IonButtons,
   IonHeader, IonIcon,
   IonMenuButton,
   IonTitle,
   IonToolbar,
   useIonRouter,
} from "@ionic/react";
import React from "react";
import {chevronBack} from "ionicons/icons";
import {RouteComponentProps, RouterProps} from "react-router";
import {IonicReactProps} from "@ionic/react/dist/types/components/IonicReactProps";

interface HeaderProps {
   title: string,
   root?: boolean,
   children?: React.ReactNode,
   icon?: string
}

export const Header: React.FC<HeaderProps> = ({title, root, children, icon}) => {

   const navigation = useIonRouter();

   return (
      <IonHeader style={{backgroundOpacity: 0.5}} color={'primary'}>
         <IonToolbar>
            <IonButtons slot="start">
               {!root && navigation.canGoBack()
                  && <IonBackButton icon={chevronBack}/>
                  || <IonMenuButton/>}
            </IonButtons>
            <IonTitle>
               {icon && <IonIcon icon={icon} slot={'start'}/>}
               {title}
            </IonTitle>
            {children}
         </IonToolbar>
      </IonHeader>
   );
}
