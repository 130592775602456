import {IonContent, IonLabel, IonPage, IonRow, IonSpinner} from "@ionic/react";
import React from "react";

interface LoadingPageProps {
   label?: string,
   name?: 'bubbles' | 'circles' | 'circular' | 'crescent' | 'dots' | 'lines' | 'lines-small',
}

export const LoadingPage: React.FC<LoadingPageProps> = ({label, name}) => {
   return (<IonRow className="ion-align-items-center ion-justify-content-center"
                   style={{height: '100%'}}>
      <div className={'ion-text-center'}>
         <IonSpinner name={name} color={'primary'}/>
         <br/> <br/>
         {label &&
            <IonLabel><h1>{label}</h1></IonLabel>}
      </div>
   </IonRow>);
}
