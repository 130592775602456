import {
   IonButton,
   IonButtons,
   IonContent,
   IonIcon,
   IonItem,
   IonLabel,
   IonList,
   IonPage, IonRow, useIonAlert, useIonModal,
} from "@ionic/react";
import {Header, LoadingPage} from "../../components";
import {add, chevronForward, square} from "ionicons/icons";
import {useEffect, useState} from "react";
import api from "../../utils/api";
import SalonsAdmin from "../../repositories/admin/salons";
import {Salon} from "../../models";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export const SalonsPage = () => {
   const repo = new SalonsAdmin(api)

   const [salons, setSalons] = useState<Salon[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   useEffect(() => {
      loadSalons()
   }, []);

   const loadSalons = async () => {
      console.log('loadSalons');
      const {data} = await repo.getSalons();
      setSalons(data);
      setLoading(false)
   }
   const [present] = useIonAlert()
   const handleNewClick = () => {
      console.log('handleNewClick');
      present({
         header: 'Nuevo Salón',
         inputs: [{
            name: 'name',
            type: 'text',
            placeholder: 'Nombre del salón',
         }],
         buttons: [{
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',


         },
            {
               text: 'Guardar',
               handler: async (data) => {
                  console.log('data', data);
                  const {data: salon} = await repo.createSalon(data);
                  setSalons([...salons, salon])
               },
            }],
      })
   }

   const handleDownloadClick = async () => {
      const blob = await repo.downloadQrs()

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'qrs.pdf';
      a.click();
      a.remove();
      // const file = window.URL.createObjectURL(blob);
      // window.location.assign(file);
   }

   return (
      <IonPage>
         <Header title="Salones" root>
            <IonButtons slot="end">
               <IonButton onClick={handleNewClick} fill={'solid'} color={'primary'}>
                  <IonIcon icon={add} slot={'start'}/> Nuevo
               </IonButton>
            </IonButtons>
         </Header>
         <IonContent fullscreen>
            {loading
               ? <LoadingPage label="Cargando salones"/>
               : <>
                  <IonList>
                     {salons.map((salon, index) => (
                        <IonItem key={index} routerLink={'/admin/salons/' + salon.id + '/mesas'}>
                           <IonIcon icon={square} slot={'start'}/>
                           <IonLabel>
                              <h2>{salon.name}</h2>
                           </IonLabel>
                           <IonIcon icon={chevronForward} slot={'end'}/>
                        </IonItem>
                     ))}
                  </IonList>

                  <IonRow className={'ion-justify-content-center'}>
                     <IonButton fill={'solid'} color={'dark'} onClick={handleDownloadClick}>
                        Descargar QRs
                     </IonButton>
                  </IonRow>
               </>
            }
         </IonContent>
      </IonPage>
   );
}
