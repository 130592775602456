import {IHttpClient} from "../../contracts";
import {Pagination, Salon} from "../../models";

export default class SalonsAdmin {

   constructor(private readonly client: IHttpClient) {
   }

   public async getSalons(): Promise<Pagination<Salon>> {
      return await this.client.get('/admin/salons').then(response => response.json());
   }

   public async getSalon(id: number): Promise<Salon> {
      return await this.client.get(`/admin/salons/${id}`).then(response => response.json());
   }

   public async createSalon(salon: Partial<Salon>) {
      return await this.client.post('/admin/salons', salon).then(response => response.json());

   }

   public async updateSalon(salon: Partial<Salon>) {
      return await this.client.put(`/admin/salons/${salon.id}`, salon).then(response => response.json());
   }

   public async deleteSalon(id: number) {
      await this.client.delete(`/admin/salons/${id}`);
   }

   public async downloadQrs() {
      return await this.client.get('/admin/qrs').then(response => response.blob());
   }
}
