import {IonApp, IonContent, IonPage, setupIonicReact} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import {Redirect, Route} from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import React, {useEffect} from "react";
import {LoadingPage, NotFound} from "./components";
import {InitMesaPage} from "./pages/InitMesaPage";
import {LoginPage} from "./pages/LoginPage";
import {useAdminStore, useAppStore, useLoginStore, useSongsStore,} from "./store";
import {mountStoreDevtool} from "simple-zustand-devtools";
import {AdminLayout} from "./pages/adminApp/AdminLayout";
import {ClientLayout} from "./pages/clientApp/ClientLayout";
import {Switch} from "react-router";

setupIonicReact();
// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
   console.log = () => {
   };
   console.error = () => {
   };
   console.debug = () => {
   };
} else {
   mountStoreDevtool("AppStore", useAppStore);
   mountStoreDevtool("LoginStore", useLoginStore);
   mountStoreDevtool("AdminStore", useAdminStore);
   mountStoreDevtool("SongsStore", useSongsStore);
}

export const App: React.FC = () => {
   const {loadState, sessionLoaded} = useLoginStore((state) => state);

   useEffect(() => {
      loadState();
   }, []);

   if (!sessionLoaded) return (<IonPage><IonContent><LoadingPage label="Cargando..."/> </IonContent> </IonPage>);

   return (
      <IonApp>
         <IonReactRouter>
            <Switch>
               <Route path="/" exact={true}>
                  <Redirect to={"/login"}/>
               </Route>
               <Route path="/login" component={LoginPage} exact={true}/>
               <Route path="/init/:mesa?" component={InitMesaPage} exact={true}/>
               <Route path="/app" component={ClientLayout}/>
               <Route path="/admin" component={AdminLayout}/>
               <Route path="*" component={NotFound}/>
            </Switch>
         </IonReactRouter>
      </IonApp>
   );
};
