import api from "../utils/api";
import {Session} from "../models";
import {IHttpClient} from "../contracts";

class AuthRepository {
   constructor(private api: IHttpClient) {
   }

   async login(data: { email: string, password: string }) {
      return await api.post('/login', data).then((res) => res.json())
   }

   async logout() {
      return await api.post('/logout').then((res) => res.json());
   }

   async initMesa(mesaHash: string): Promise<Session> {
      return await this.api.get(`/init/${mesaHash}`).then((res) => res.json());
   }

   statusAdmin(): Promise<boolean> {
      return Promise.resolve(true);
   }

   async statusClient() {
      const data: { status: 'ok' } = await this.api.get('/status').then((res) => res.json());
      return data.status === 'ok'
   }

}

export default AuthRepository;
