import {IHttpClient} from "../../contracts";
import {PaginationFilter, Song} from "../../models";
import {queryParams} from "../../utils/UrlSearchParams";

class SongsAdmin {
   constructor(private readonly api: IHttpClient) {
   }

   async getAll(filter: PaginationFilter) {
      const query = queryParams(filter);
      return await this.api.get(`/admin/songs${query}`).then((res) => res.json())
   }

   async get(songId: number | string) {
      return await this.api.get(`/admin/songs/${songId}`).then((res) => res.json())
   }

   async update(songId: number | string, song: Partial<Song> | FormData) {
      return await this.api.put(`/admin/songs/${songId}`, song).then((res) => res.json())
   }

   async delete(songId: number) {
      return await this.api.delete(`/admin/songs/${songId}`).then((res) => res.json())
   }

   async create(formData: FormData) {
      return await this.api.post(`/admin/songs`, formData).then((res) => res.json())
   }
}


export default SongsAdmin;
