import {Header, LoadingPage} from "../../components";
import {
   IonButton,
   IonButtons,
   IonContent,
   IonIcon,
   IonItem,
   IonList,
   IonPage,
   useIonAlert,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import MesasAdmin from "../../repositories/admin/mesas";
import api from "../../utils/api";
import {useParams} from "react-router";
import {Mesa} from "../../models";
import {add, people, trash} from "ionicons/icons";

export const MesasPage = () => {
   const repo = new MesasAdmin(api)
   const [loading, setLoading] = useState<boolean>(true);
   const [mesas, setMesas] = useState<Mesa[]>([]);
   const {salonId} = useParams<{ salonId: string }>();

   useEffect(() => {
      loadMesas()
   }, [salonId])

   const loadMesas = async () => {
      const {data} = await repo.getAll(salonId);
      setMesas(data);
      setLoading(false)
   }

   const [present] = useIonAlert()
   const handleRemoveClick = async (mesa: Mesa) => {
      present({
         header: 'Eliminar mesa',
         message: `¿Está seguro que desea eliminar la mesa ${mesa.name}?`,
         buttons: [{
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
         },
            {
               text: 'Eliminar',
               cssClass: 'danger',
               handler: async () => {
                  await repo.delete(salonId, mesa.id);
                  setMesas(mesas.filter(m => m.id !== mesa.id))
               },
            }],
      })
   }

   const handleAddClick = async () => {
      present({
         header: 'Nueva mesa',
         inputs: [{name: 'name'}],
         buttons: [
            {text: 'Cancelar', role: 'cancel', cssClass: 'secondary'},
            {
               text: 'Guardar',
               handler: async (data) => {
                  const {data: mesa} = await repo.create(salonId, data);
                  setMesas([...mesas, mesa])
               },
            }],
      })
   }

   return (
      <IonPage>
         <Header title={'Mesas'}>
            <IonButtons slot={'end'}>
               <IonButton onClick={handleAddClick} fill={'solid'} color={'primary'}>
                  <IonIcon icon={add} slot={'start'}/> Nuevo
               </IonButton>
            </IonButtons>
         </Header>
         <IonContent fullscreen>
            {loading
               ? <LoadingPage label={'Cargando mesas'}/>
               : <IonList>
                  {mesas.map((mesa, index) => (
                     <IonItem key={index}>
                        <IonIcon icon={people} slot={'start'}/>
                        {mesa.name}
                        <IonButtons slot={'end'}>
                           <IonButton color={'danger'}
                                      onClick={() => handleRemoveClick(mesa)}><IonIcon
                              icon={trash}/></IonButton>
                        </IonButtons>
                     </IonItem>
                  ))}
               </IonList>}
         </IonContent>
      </IonPage>
   );
}
