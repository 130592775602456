import React from "react";
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {chevronForward} from "ionicons/icons";

interface ListDescriptionProps {
   title: string;
   value?: string | number;
   icon?: string;
   vertical?: boolean;
   to?: string;
}

export const ListDescription: React.FC<ListDescriptionProps> = (
   {title, value = "", vertical = false, to},
) => {
   return <IonItem className={"ion-text-capitalize"} routerLink={to}>
      {vertical
         ? <IonLabel><p> {title} </p>{value}</IonLabel>
         : <>
            <IonLabel>{title}</IonLabel>
            <IonLabel slot={"end"}>{value}</IonLabel>
         </>}
      {to && <IonIcon icon={chevronForward} slot="end"/>}
   </IonItem>;
};
