import {IHttpClient} from "../../contracts";
import {Order, PaginationFilter, Pagination} from "../../models";
import {queryParams} from "../../utils/UrlSearchParams";

class OrdersAdmin {
   constructor(private api: IHttpClient) {
   }

   async getAll(filter: PaginationFilter): Promise<Pagination<Order>> {
      const query = queryParams(filter);
      return await this.api.get(`/admin/orders${query}`).then((res) => res.json());
   }

   async update(orderId: number, {state, reason}: Partial<Order>) {
      return await this.api.put(`/admin/orders/${orderId}`, {
         state,
         reason
      }).then((res) => res.json())
   }
}

export default OrdersAdmin;
