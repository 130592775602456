import {IHttpClient} from "../../contracts";
import {Mesa} from "../../models";

export default class MesasAdmin {
   constructor(private readonly api: IHttpClient) {
   }

   async getAll(salonId: string) {
      return await this.api.get(`/admin/salons/${salonId}/mesas`).then((res) => res.json())
   }

   async get(salonId: number, mesaId: number) {
      return await this.api.get(`/admin/salons/${salonId}/mesas/${mesaId}`).then((res) => res.json())
   }

   async create(salonId: string, mesa: Partial<Mesa>) {
      return await this.api.post(`/admin/salons/${salonId}/mesas`, mesa).then((res) => res.json())
   }

   async update(salonId: number, mesaId: number, mesa: Partial<Mesa>) {
      return await this.api.put(`/admin/salons/${salonId}/mesas/${mesaId}`, mesa).then((res) => res.json())
   }

   async delete(salonId: string, mesaId: number) {
      return await this.api.delete(`/admin/salons/${salonId}/mesas/${mesaId}`).then((res) => res.json())
   }
}
