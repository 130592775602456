import {
   InfiniteScrollCustomEvent,
   IonButton,
   IonButtons,
   IonContent,
   IonIcon,
   IonInfiniteScroll,
   IonInfiniteScrollContent,
   IonItem,
   IonLabel,
   IonList,
   IonPage, useIonAlert, useIonViewDidEnter,
} from "@ionic/react";
import {Header} from "../../components";
import UsersAdmin from "../../repositories/admin/users";
import api from "../../utils/api";
import {useEffect, useState} from "react";
import {PaginationMeta, User} from "../../models";
import {personAddOutline, personOutline, trash} from "ionicons/icons";

export const UsersPage = () => {

   const repo = new UsersAdmin(api)
   const [users, setUsers] = useState<User[]>([])
   const [meta, setMeta] = useState<PaginationMeta>()

   useIonViewDidEnter(() => {
      loadUsers();
   })

   const loadUsers = async (page = 1) => {
      const {data, meta} = await repo.getAll({page})
      setUsers(data)
      setMeta(meta)
   }

   const loadMore = async (e: InfiniteScrollCustomEvent) => {

      if (!meta) return
      if (meta.current_page < meta.last_page) {
         await loadUsers(meta.current_page + 1)
      }
      await e.target.complete()
   }
   const [present] = useIonAlert();
   const handleRemoveClick = async (user: User) => {
      present({
         subHeader: user.email,
         message: '¿Estás seguro de eliminar este usuario?',
         buttons: [
            'Cancelar',
            {
               cssClass: 'danger',
               text: 'Eliminar', role: 'destructive',
               handler: async () => {
                  await repo.delete(user.id)
                  setUsers(users.filter(u => u.id !== user.id))
               },
            },
         ],
      })
   }
   return (
      <IonPage>
         <Header title={'Usuarios'} root>
            <IonButtons slot="end">
               <IonButton routerLink={'/admin/users/new'} fill={'solid'} color={'primary'}>
                  <IonIcon icon={personAddOutline} slot="start"/> Nuevo
               </IonButton>
            </IonButtons>
         </Header>
         <IonContent fullscreen>
            <IonList>
               {users.map((user, index) => (<IonItem key={index}>
                  <IonIcon icon={personOutline} slot="start"/>
                  <IonLabel>
                     {user.name}
                     <p>{user.email}</p>
                  </IonLabel>
                  <IonButton slot="end" color="danger" onClick={() => handleRemoveClick(user)}>
                     <IonIcon icon={trash}/> Borrar
                  </IonButton>
               </IonItem>))}
            </IonList>
            <IonInfiniteScroll onIonInfinite={loadMore}>
               <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
         </IonContent>
      </IonPage>
   );
}
