import {
   IonAvatar,
   IonButton,
   IonButtons,
   IonIcon,
   IonImg,
   IonItem,
   IonLabel,
} from "@ionic/react";
import {chevronForward, personOutline} from "ionicons/icons";
import {Song} from "../../../models";
import React from "react";
import {remoteUrl} from "../../../utils/url";

export interface SongListItemProps {
   song: Song;
}

export const SongListItem: React.FC<SongListItemProps> = ({song}) => {
   return (
      <IonItem routerLink={`/admin/songs/${song.id}`}>
         <IonAvatar slot="start">
            <IonImg src={remoteUrl(song.image)}/>
         </IonAvatar>
         <IonLabel>
            <h3> {song.name} </h3>
            <p>
               <IonIcon icon={personOutline}/> {song.artist.name}
            </p>
         </IonLabel>
         <IonButtons slot="end">
            <IonButton>
               {" "}
               <IonIcon icon={chevronForward}/>
            </IonButton>
         </IonButtons>
      </IonItem>
   );
};
