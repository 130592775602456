import {IonContent, IonPage, IonRow, IonTitle} from "@ionic/react";
import React from "react";
import {RouteComponentProps} from "react-router";

export const NotFound: React.FC<RouteComponentProps> = ({match}) => {
   console.log('NotFound', match)
   return (
      <IonPage>
         <IonContent>
            <IonRow className='ion-align-items-center' style={{height: '100%'}}>
               <IonTitle className='ion-align-self-center ion-text-center'>
                  <h1>404</h1>
                  <p>No se encontró</p>
               </IonTitle>
            </IonRow>
         </IonContent>
      </IonPage>
   );
}
