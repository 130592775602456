import {
   IonContent,
   IonIcon,
   IonItem,
   IonLabel,
   IonList,
   IonMenu,
   IonMenuToggle,
   useIonRouter,
} from "@ionic/react";
import {
   grid,
   logOut,
   mic,
   micCircle,
   micOutline,
   musicalNotes,
   people,
   person,
} from "ionicons/icons";
import React from "react";
import {useLoginStore} from "../../../store";
import {useLocation} from "react-router";
import Auth from "../../../repositories/auth";
import api from "../../../utils/api";

interface IAppPages {
   title: string;
   link: string;
   icon: string;
}

const appPages: IAppPages[] = [
   {
      title: 'Pedidos',
      link: '/admin/orders',
      icon: mic,
   },
   {
      title: 'Canciones',
      link: '/admin/songs',
      icon: musicalNotes,
   },
   {
      title: 'Artistas',
      link: '/admin/artists',
      icon: people,
   },
   {
      title: 'Salones',
      link: '/admin/salons',
      icon: grid,
   },
   {
      title: 'Usuarios',
      link: '/admin/users',
      icon: person,
   },
]

export const MenuAdmin = () => {

   const repo = new Auth(api)

   const {session, logout} = useLoginStore(state => state);
   const location = useLocation()
   const navigation = useIonRouter()
   const handleLogout = async () => {
      await repo.logout()
      logout();
      navigation.push('/login', 'root', 'replace')
   }

   return (
      <IonMenu contentId="main" type="overlay">
         <IonContent>
            <IonItem className="ion-margin-vertical">
               <IonIcon icon={person} color="success" slot="end"/>
               <IonLabel>
                  {session?.user?.name}
                  <p>{session?.user?.email}</p>
               </IonLabel>
            </IonItem>
            <IonList id="inbox-list">
               {appPages.map((appPage, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                     <IonItem
                        className={location.pathname.startsWith(appPage.link) ? 'selected' : ''}
                        routerLink={appPage.link} routerDirection="root" lines="full"
                        detail={false}>
                        <IonIcon slot="start" icon={appPage.icon}/>
                        <IonLabel>{appPage.title}</IonLabel>
                     </IonItem>
                  </IonMenuToggle>
               ))}
               <IonMenuToggle autoHide={false}>
                  <IonItem onClick={handleLogout} button detail={false}>
                     <IonIcon icon={logOut} slot="start"/>
                     <IonLabel>Cerrar Sesión</IonLabel>
                  </IonItem>
               </IonMenuToggle>

            </IonList>


         </IonContent>
      </IonMenu>
   );
}
