import {
   IonAvatar, IonButton,
   IonCard,
   IonCardContent,
   IonContent, IonIcon,
   IonInput,
   IonItem,
   IonLabel,
   IonPage, IonRow, useIonRouter,
} from "@ionic/react";
import {Header} from "../../components";
import {useForm} from "react-hook-form";
import {User} from "../../models";
import {person, personAdd} from "ionicons/icons";
import {ErrorHookForm} from "../../components/ErrorHookForm";
import UsersAdmin from "../../repositories/admin/users";
import api from "../../utils/api";

export const UserNewPage = () => {
   const repo = new UsersAdmin(api)
   const {register, handleSubmit, formState: {errors}} = useForm<Partial<User>>()
   const navigation = useIonRouter()
   const onSubmit = async (data: any) => {
      await repo.create(data)
      navigation.goBack()
   };
   return (
      <IonPage>
         <Header title="Nuevo usuario"/>
         <IonContent fullscreen>
            <form onSubmit={handleSubmit(onSubmit)}>
               <IonCard>
                  <IonRow className="ion-justify-content-center ion-padding-vertical">
                     <IonIcon icon={person} style={{fontSize: '5rem'}}/>
                  </IonRow>
                  <IonCardContent>
                     <IonItem>
                        <IonLabel position="floating">Nombre</IonLabel>
                        <IonInput {...register('name', {required: 'Este campo es requerido'})}/>
                        <ErrorHookForm errors={errors} field="name"/>

                     </IonItem>
                     <IonItem>
                        <IonLabel position="floating">Correo</IonLabel>
                        <IonInput
                           type="email" {...register('email', {required: 'Este campo es requerido'})}/>
                        <ErrorHookForm errors={errors} field="email"/>

                     </IonItem>
                     <IonItem>
                        <IonLabel position="floating">Contraseña</IonLabel>
                        <IonInput
                           type="password" {...register('password', {required: 'Este campo es requerido'})}
                           autocomplete="off"/>
                        <ErrorHookForm errors={errors} field="password"/>
                     </IonItem>

                     <IonButton type="submit" expand="block">Guardar</IonButton>

                  </IonCardContent>
               </IonCard>
            </form>
         </IonContent>
      </IonPage>
   );
}
