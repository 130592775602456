import {
   InfiniteScrollCustomEvent,
   IonAvatar,
   IonButton,
   IonButtons,
   IonContent,
   IonIcon,
   IonImg,
   IonInfiniteScroll,
   IonInfiniteScrollContent,
   IonItem,
   IonLabel,
   IonList,
   IonPage,
   IonVirtualScroll,
   useIonAlert,
   useIonViewDidEnter,
   useIonViewWillLeave,
} from "@ionic/react";
import {Header, LoadingPage} from "../../components";
import api from "../../utils/api";
import {useEffect, useState} from "react";
import {Pagination, PaginationMeta, Song} from "../../models";
import {add, pencil, trash} from "ionicons/icons";
import SongsAdmin from "../../repositories/admin/songs";
import {SongListItem} from "./components/SongListItem";
import {useHistory} from "react-router";
import {useSongsStore} from "../../store";

export const SongsPage = () => {
   const repo = new SongsAdmin(api);
   const [meta, setMeta] = useState<PaginationMeta>();
   const {songs, addSongs, clearSongs} = useSongsStore((state) => state);
   const [loading, setLoading] = useState<boolean>(true);
   const loadSongs = async (page = 1) => {
      const {meta, data} = await repo.getAll({page});
      setMeta(meta);
      addSongs(data);
   };

   useEffect(() => {
      clearSongs()
      loadSongs().then(() => setLoading(false));
   }, []);

   const loadMore = async (e: InfiniteScrollCustomEvent) => {
      console.log("loading more songs", meta);
      if (!meta) return;
      if (meta.current_page < meta.last_page) {
         await loadSongs(meta.current_page + 1);
      }
      await e.target.complete();
   };

   return (
      <IonPage>
         <Header title={"Canciones"} root/>
         {loading ? <LoadingPage label={'Cargando canciones'}/>
            :
            <IonContent fullscreen>
               <IonList>
                  {songs.map((song, index) => (
                     <SongListItem song={song} key={index}/>
                  ))}
               </IonList>
               <IonInfiniteScroll onIonInfinite={loadMore}>
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
               </IonInfiniteScroll>
            </IonContent>}
      </IonPage>
   );
};
