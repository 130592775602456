import {create} from "zustand";
import {Artist, Song} from "../models";

interface IAdminStore {
}

export const useAdminStore = create<IAdminStore>((set) => ({}));

interface ISongsStore {
   songs: Song[]
   editSong: Song | null
   deletedSongs: number[]

   addSongs(songs: Song[]): void

   addSong(song: Song): void

   deleteSong(songId: number): void

   clearSongs(): void

   update(song: Song): void;

   addEdit(song: Song): void;

   clearEdit(): void;

   addDeleted(songId: number): void;

   clearDeleted(): void;
}

export const useSongsStore = create<ISongsStore>((set) => ({
   deletedSongs: [],
   editSong: null,
   songs: [],
   addSongs(songs: Song[]): void {
      set((state) => ({...state, songs: [...state.songs, ...songs]}));
   },
   addSong(song: Song): void {
      set((state) => ({...state, songs: [...state.songs, song]}));
   },
   clearSongs(): void {
      console.log('clearSongs')
      set((state) => ({...state, songs: []}));
   },
   deleteSong(songId: number): void {
      set((state) => ({
         ...state,
         songs: state.songs.filter((song) => song.id !== songId),
      }));
   },
   update(song: Song): void {
      set((state) => ({
         ...state,
         songs: state.songs.map((s) => s.id === song.id ? song : s),
      }));
   },

   addEdit: (song: Song) => {
      set((_) => ({editSong: song}));
   },
   clearEdit: () => {
      set((_) => ({editSong: null}));
   },
   addDeleted: (songId: number) => {
      set(({deletedSongs}) => ({
         deletedSongs: [...deletedSongs, songId],
      }));
   },
   clearDeleted: () => {
      set((_) => ({deletedSongs: []}));
   },
}));


export interface IArtistsStore {
   artists: Artist[];

   addArtists(artists: Artist[]): void;

   addArtist(artist: Artist): void;

   clearArtists(): void;

   deleteArtist(artistId: number | string): void;

   updateArtist(artist: Artist): void;
}

export const useArtistsStore = create<IArtistsStore>((set) => ({
   artists: [],
   addArtists: (artists: Artist[]) => {
      set((state: any) => ({...state, artists: [...state.artists, ...artists]}));
   },
   addArtist: (artist: Artist) => {
      set((state: any) => ({...state, artists: [...state.artists, artist]}));
   },
   clearArtists: () => {
      set((state: any) => ({...state, artists: []}));
   },
   deleteArtist: (artistId: number) => {
      set((state: any) => ({
         ...state,
         artists: state.artists.filter((artist: Artist) => artist.id !== artistId),
      }));
   },
   updateArtist: (artist: Artist) => {
      set((state: any) => ({
         ...state,
         artists: state.artists.map((a: Artist) => a.id === artist.id ? artist : a),
      }));
   },

}));

