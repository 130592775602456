import {
   IonButton,
   IonCol,
   IonContent,
   IonImg,
   IonInput,
   IonItem,
   IonLabel,
   IonPage,
   IonRange,
   IonRow,
   IonTextarea,
   useIonRouter,
} from "@ionic/react";
import {useParams} from "react-router";
import {Header} from "../../components";
import {useEffect, useState} from "react";
import ArtistsAdmin from "../../repositories/admin/artists";
import api from "../../utils/api";
import {Artist} from "../../models";
import {useForm} from 'react-hook-form'
import {remoteUrl} from "../../utils/url";
import {useArtistsStore} from "../../store";

export const ArtistEditPage = () => {
   const repo = new ArtistsAdmin(api)

   const {artistId} = useParams<{ artistId: string }>();
   const [isNew, setIsNew] = useState(false);
   const [image, setImage] = useState('')
   const [file, setFile] = useState<File | null>(null)

   const {register, handleSubmit, reset, setValue} = useForm<Artist>()
   const navigation = useIonRouter()
   const {updateArtist} = useArtistsStore(state => state)


   useEffect(() => {
      if (artistId && artistId !== 'new') {
         loadArtist();
         setIsNew(false);
      }
      else setIsNew(true)
   }, [artistId]);

   const loadArtist = async () => {
      console.log(artistId)
      if (artistId === 'new') return
      const {data} = await repo.get(artistId);
      setImage(remoteUrl(data.image))
      reset(data)
   }
   const onSubmit = async (artist: any) => {
      const formData = new FormData()
      formData.append('name', artist.name)
      formData.append('description', artist.description)
      formData.append('file', file!)
      formData.append('ranking', artist.ranking)

      const {data} = isNew ? await repo.store(formData)
         : await repo.update(artistId, formData)
      updateArtist(data)
      navigation.goBack()
   }

   const handleImageChange = (e: any) => {
      console.log(e)
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e: any) => {
         setImage(e.target.result)
         setFile(file)
      }
      reader.readAsDataURL(file)
   }

   return (
      <IonPage>
         <Header title={isNew ? 'Nuevo Artista' : 'Editar Artista'}>
            <IonButton slot="end" fill="solid" color="primary"
                       onClick={handleSubmit(onSubmit)}> Guardar </IonButton>
         </Header>
         <IonContent fullscreen>
            <IonRow>
               <IonCol size="12" sizeMd="4" className="ion-text-center">
                  {image && <IonImg src={image} style={{width: '100%'}}/>}
               </IonCol>
               <IonCol size="12" sizeMd={image ? '8' : '12'}>
                  <IonItem fill="solid">
                     <IonLabel position="floating">Nombre</IonLabel>
                     <IonInput {...register("name", {required: true})} />
                  </IonItem>
                  <IonItem>
                     <IonLabel position="floating">Descripción</IonLabel>
                     <IonTextarea {...register('description')} rows={5}/>
                  </IonItem>
                  <IonItem>
                     <IonLabel position="fixed">Ranking </IonLabel>
                     <IonRange {...register('ranking')} ticks snaps pin min={1} max={5}
                               slot={'end'}/>
                  </IonItem>
                  <IonItem>
                     <IonLabel position="fixed">Imágen</IonLabel>
                     <input type={'file'} accept="image/*" onChange={handleImageChange}
                            slot={'end'}/>
                  </IonItem>

               </IonCol>

            </IonRow>
         </IonContent>
      </IonPage>
   );
}
