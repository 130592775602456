import {
   IonButton,
   IonCard,
   IonCardContent,
   IonCol,
   IonContent,
   IonImg, IonItem, IonLabel,
   IonList,
   IonPage,
   IonRow,
   IonToolbar,
   useIonAlert,
   useIonRouter, useIonViewDidEnter,
} from "@ionic/react";
import {Header, LoadingPage, Ranking} from "../../components";
import {useParams} from "react-router";
import SongsAdmin from "../../repositories/admin/songs";
import api, {ApiErrorException} from "../../utils/api";
import React, {useEffect, useState} from "react";
import {Song} from "../../models";
import {useSongsStore} from "../../store";
import {ListDescription} from "../../components/ListDescription";
import {remoteUrl} from "../../utils/url";


export const SongPage: React.FC = () => {

   const repo = new SongsAdmin(api);
   const {songId} = useParams<{ songId: string }>();
   const [song, setSong] = useState<Song>();
   const navigation = useIonRouter();
   const [present] = useIonAlert();
   const {deleteSong} = useSongsStore((state) => state)
   const [loading, setLoading] = useState(true);

   useIonViewDidEnter(() => {
      loadSong().then(() => setLoading(false));
   });

   const handleRemoveClick = async () => {
      await present({
         message: "¿Estás seguro de eliminar esta canción?",
         subHeader: song!.name,
         buttons: [
            {text: "Cancelar", role: "cancel"},
            {text: "Eliminar", handler: removeSong},
         ],
      });
   };
   const loadSong = async () => {
      const {data} = await repo.get(songId);
      setSong(data);
   };
   const handleEditSong = () => {
      navigation.push(`/admin/songs/${song!.id}/edit`);
   };
   const removeSong = async () => {
      if (!song) return
      await repo.delete(song!.id);
      deleteSong(song.id);
      navigation.goBack();
   };

   return (
      <IonPage>
         <Header title="Canción"/>
         {(!song || loading)
            ? <LoadingPage label="Cargando datos"/>
            : <IonContent fullscreen>
               <IonCard>
                  <IonRow>
                     <IonCol size={"12"} sizeSm={"4"}>
                        <IonImg src={remoteUrl(song.image)}/>
                     </IonCol>
                     <IonCol size={"12"} sizeSm={"8"}>
                        <IonCardContent>
                           <h1>{song.name}</h1>
                           <p>{song.description}</p>

                           <IonList>
                              <ListDescription title="Artista" value={song.artist.name} vertical/>
                              <ListDescription title="Genero" value={song.genre}/>
                              {/*<ListDescription title="Ranking" childen={song.ranking.toString()}/>*/}
                              <IonItem>
                                 <IonLabel> Ranking </IonLabel>
                                 <Ranking rank={song.ranking} slot={'end'}/>
                              </IonItem>
                              <ListDescription title="Duración" value={song.duration}/>
                              <ListDescription title="Año" value={song.year?.toString()}/>
                              <ListDescription title="Idioma" value={song.language}/>
                           </IonList>
                        </IonCardContent>
                        <IonToolbar>
                           <IonRow className={"ion-justify-content-between"}>
                              <IonCol>
                                 <IonButton onClick={handleEditSong} color={"primary"}
                                            expand={"block"}>
                                    Editar
                                 </IonButton>
                              </IonCol>
                              <IonCol>
                                 <IonButton onClick={handleRemoveClick} color={"danger"}
                                            fill={"solid"} expand={"block"}>
                                    Eliminar
                                 </IonButton>
                              </IonCol>
                           </IonRow>
                        </IonToolbar>
                     </IonCol>
                  </IonRow>
               </IonCard>
            </IonContent>}
      </IonPage>
   );
};
