import {Mesa} from "./Mesa";
import {Song} from "./Song";

export enum OrderStates {
   Aceptado = 'aceptado',
   Pendiente = 'pendiente',
   Rechazado = 'rechazado',
   Reproducido = 'reproducido',
}

export interface Order {
   id: number;
   session_id: string;
   mesa_id: number;
   song_id: number;
   state: string;
   reason?: string;
   created_at: number;
   mesa: Mesa;
   song: Song;
}
