import {Session} from "../models";
import {create, createStore} from "zustand";

interface ILoginStore {
   session: Session | null;
   sessionLoaded: boolean,
   loadState: () => void,
   logout: () => void,
   setSession: (session: Session) => void;
}

export const useLoginStore = create<ILoginStore>(set => ({

   session: null,
   sessionLoaded: false,

   logout: () => {
      localStorage.removeItem('token')
      localStorage.removeItem('state')
      set(state => ({...state, session: null}))
   },

   setSession: session => set(state => {
      const newState = {...state, session}
      localStorage.setItem('token', session.token || '')
      localStorage.setItem('state', JSON.stringify(newState))
      return newState
   }),

   loadState: () => {
      const state = localStorage.getItem('state')
      if (state) set({...JSON.parse(state), sessionLoaded: false})
      set(state => ({...state, sessionLoaded: true}))
   },

}))
