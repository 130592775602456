import {IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonNote,} from '@ionic/react';
import {calendarClear, ellipse, musicalNotes} from 'ionicons/icons';
import './MenuClient.css';
import React from "react";
import {Ranking} from "../../../components";
import moment from 'moment';
import {useLoginStore} from "../../../store";

const labels: String[] = []

export const MenuClient: React.FC = () => {

   const {session} = useLoginStore(state => state);
   const fecha = moment().format('DD/MM/YYYY');
   return (
      <IonMenu contentId="main" type="overlay">
         <IonContent>
            <IonList id="inbox-list">
               <IonListHeader>
                  <IonIcon icon={ellipse} color='success'/>
                  {session?.mesa}
               </IonListHeader> <br/>
               <IonNote>
                  <IonIcon icon={calendarClear}> </IonIcon>
                  {fecha}</IonNote>

               {/*{appPages.map((appPage, index) => (*/}
               {/*   <IonMenuToggle key={index} autoHide={false}>*/}
               {/*      <IonItem className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}*/}
               {/*         routerLink={appPage.url}*/}
               {/*         routerDirection="none"*/}
               {/*         lines="none"*/}
               {/*         detail={false}>*/}
               {/*         <IonIcon slot="start" icon={appPage.mdIcon}/>*/}
               {/*         <IonLabel>{appPage.title}</IonLabel>*/}
               {/*      </IonItem>*/}
               {/*   </IonMenuToggle>*/}
               {/*))}*/}

            </IonList>

            {labels.length > 0 &&
               <IonList id="labels-list">
                  <IonListHeader> <IonIcon icon={musicalNotes}></IonIcon> Historial</IonListHeader>
                  {!!labels.length && labels.map((label, index) => (
                     <IonItem lines='full' key={index}>
                        <IonLabel>{label}</IonLabel>
                        <Ranking rank={3}/>
                     </IonItem>
                  ))}
               </IonList>
            }

         </IonContent>
      </IonMenu>
   );
};


