import React, {useEffect} from "react";
import {RouteComponentProps, useParams} from "react-router";
import {useIonRouter, useIonViewWillEnter} from "@ionic/react";
import {LoadingPage} from "../components";
import {useLoginStore} from "../store";
import api from "../utils/api";
import Auth from "../repositories/auth";

export const InitMesaPage: React.FC<RouteComponentProps> = ({match}) => {
   console.log('InitMesaPage', match)

   const {setSession} = useLoginStore(state => state);

   const [error, setError] = React.useState<string>('Obteniendo acceso');

   const {mesa} = useParams<{ mesa: string }>();
   const navigation = useIonRouter();

   useEffect(() => {
      console.log('useEffect')
      validHash()
   }, []);

   const validHash = async () => {
      console.log('validHash', mesa)
      const auth = new Auth(api);
      try {
         const res = await auth.initMesa(mesa);
         if (res.token) {
            setSession(res);
            navigation.push(`/app/orders`, 'root', 'replace');
         }
         else setError('Ocurrio un error al acceder al sistema')
      } catch (error) {
         setError(error + '');
      } finally {
      }
   }

   useIonViewWillEnter(async () => {
      console.log('useIonViewDidEnter')
   })


   return (<LoadingPage label={error}/>);
}

