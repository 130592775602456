import {IHttpClient} from "../../contracts";
import {Pagination, PaginationFilter, User} from "../../models";
import {queryParams} from "../../utils/UrlSearchParams";

export default class UsersAdmin {
   constructor(private readonly api: IHttpClient) {
   }

   public async get(userId: number): Promise<User> {
      return await this.api.get('/admin/users/' + userId).then((r) => r.json());
   }

   public async create(user: Partial<User>): Promise<User> {
      return await this.api.post('/admin/users', user).then((r) => r.json());
   }

   public async update(userId: number, user: Partial<User>): Promise<User> {
      return await this.api.put(`/admin/users/${userId}`, user).then((r) => r.json());
   }

   async getAll(filter: PaginationFilter): Promise<Pagination<User>> {
      const query = queryParams(filter)
      return await this.api.get(`/admin/users${query}`).then((r) => r.json())
   }

   async delete(userId: number) {
      return await this.api.delete(`/admin/users/${userId}`).then((r) => r.json())
   }
}
