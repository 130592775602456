import {Artist, PaginationFilter, Pagination, Song} from "../../models"
import {IHttpClient} from "../../contracts";
import {queryParams} from "../../utils/UrlSearchParams";

class ArtistsAdmin {
   constructor(private client: IHttpClient) {
   }

   async getAll(filter: PaginationFilter): Promise<Pagination<Artist>> {
      const query = queryParams(filter)
      return await this.client.get(`/admin/artists${query}`).then((res) => res.json())
   }

   async get(artistId: string) {
      return await this.client.get(`/admin/artists/${artistId}`).then((res) => res.json())
   }

   async store(data: object) {
      return await this.client.post(`/admin/artists`, data).then((res) => res.json())
   }

   async update(artistId: string, data: object) {
      return await this.client.put(`/admin/artists/${artistId}`, data).then((res) => res.json())
   }

   async delete(artistId: number | string) {
      return await this.client.delete(`/admin/artists/${artistId}`).then((res) => res.json())
   }

   async getSongs(artistId: string, filter: PaginationFilter): Promise<Pagination<Song>> {
      const query = queryParams(filter)
      return await this.client.get(`/admin/artists/${artistId}/songs${query}`).then((res) => res.json())
   }

   async getSong(artistId: string, songId: string) {
      return await this.client.get(`/admin/artists/${artistId}/songs/${songId}`).then((res) => res.json())
   }

   async storeSong(artistId: string, data: Partial<Song>) {
      return await this.client.post(`/admin/artists/${artistId}/songs`, data).then((res) => res.json())
   }

   async updateSong(artistId: string, songId: string, data: Partial<Song>) {
      return await this.client.put(`/admin/artists/${artistId}/songs/${songId}`, data).then((res) => res.json())
   }

   async import(formData: FormData) {
      return await this.client.post(`/admin/artists/import`, formData).then((res) => res.json())
   }
}

export default ArtistsAdmin;
