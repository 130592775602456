import {IHttpClient} from "../contracts";

const endpoint = process.env.NODE_ENV === 'production' ?
   'https://api.karaoke.fretelweb.com/api/v1' : 'http://localhost:3333/api/v1'
export const imageUrl = process.env.NODE_ENV === 'production' ?
   'https://api.karaoke.fretelweb.com/uploads' : 'http://localhost:3333/uploads'

export interface IHttpErrorException {
   message: string;
   status: number;

   toString(): string;
}

export class ApiErrorException implements IHttpErrorException {
   constructor(public status: number, public message: string) {
   }

   getStatus() {
      return this.status;
   }

   getMessage() {
      return this.message;
   }

   toString() {
      return this.message;
   }
}

class Api implements IHttpClient {

   async request(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, body?: any) {

      const headers: HeadersInit = [['Authorization', `Bearer ${this.token()}`]]
      if (body && !(body instanceof FormData)) {
         body = JSON.stringify(body);
         headers.push(['Content-Type', 'application/json'])
      }

      const res = await fetch(endpoint + url, {method, body, headers})
      if (res.status === 401) {
         localStorage.removeItem('token')
         localStorage.removeItem('state')
         window.location.href = '/login'
      }

      if (res.ok) return res;
      else {
         const data = await res.json();
         throw new ApiErrorException(res.status, data.message || res.statusText);
      }
   }

   token(): string | null {
      return localStorage.getItem('token');
   }

   delete(url: string): Promise<Response> {
      return this.request('DELETE', url);
   }

   get(url: string): Promise<Response> {
      return this.request('GET', url);
   }

   post(url: string, body?: any): Promise<Response> {
      return this.request('POST', url, body);
   }

   put(url: string, body?: any): Promise<Response> {
      return this.request('PUT', url, body);
   }

}

export default new Api();





